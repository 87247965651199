<template>
  <div class="mx-cabinet-container" :style="maxWidth === 0 ? '' : `max-width: ${maxWidth}px`">
    <mx-preloader :z-index="zIndex" :logo="logo || options.preload_logo" :spiner="spiner" :loader-status="preloader"/>
    <div v-if="preloader === false" class="mx-cabinet-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preloader: {
      type: Boolean,
      default: true,
      required: true,
    },
    zIndex: {
      type: Number,
      default: 100
    },
    logo: {
      type: Boolean,
      default: false
    },
    spiner: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: Number,
      default: 0
    }
  }
}
</script>

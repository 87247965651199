<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'faq.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="mx-form-header">
<!--          <h4 class="mb-3">{{ $t('faq.question.create') }}</h4>-->
        </div>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-prompt">
                <h3 class="mx-modal-prompt__title">{{ $t('faq.question.csv.prompt.title') }}</h3>
                <strong class="mx-modal-prompt__subtitle">{{ $t('faq.question.csv.prompt.subtitle') }}</strong>
                <input type="file" accept="text/csv" style="margin: 15px 0 50px 0;" v-on:change="csvFileChange">
              </div>
            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'faq.show', params: {id: $route.params.faq_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled || csvFile === null" @click="save" type="button" class="btn btn-peepz-success">
              {{ $t('faq.question.csv.load') }}
            </button>
          </div>
        </form>

      </div>
    </div>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft,
  PhTrashSimple
} from "phosphor-vue";
import axios from "axios";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft, PhTrashSimple},
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      csvFile: null,
      errors: []
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.preloader = false
  },
  methods: {
    save() {
      this.button_disabled = true;

      let formData = new FormData()
      formData.append('id', this.$route.params.faq_id)
      formData.append('file', this.csvFile)

      axios.post(`question/csv/import/${this.$route.params.faq_id}`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$router.replace({
          name: 'faq.show', params: {id: this.$route.params.faq_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    csvFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.csvFile = files[0]
    }
  }
}
</script>
